//import logo from "./logo.svg";
import "./App.css";
import Paper from "@mui/material/Paper";
import { FaLinkedin, FaTwitter, FaMailBulk, FaLink } from "react-icons/fa"
//import FormComp from "./components/FormComp";
import { Container, Grid } from "@mui/material";
import ProductOwnerDetails from "./Page/ProductOwnerDetails";
import ProductSpecifications from "./Page/ProductSpecifications";
import { useEffect, useState } from "react";
import DataClassification from "./Page/DataClassification";
import ExistingServiceRelations from "./Page/ExistingServiceRelations";
//import Button from "@mui/material/Button";
import * as React from "react";
import Header from "./image"
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
//import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FileUploads from "./Page/FileUploads";
import { db } from "./firebase";

const setLocalStorage = (field, value) => {
  localStorage.setItem(field, value);
};
const getLocalStorage = field => {
  return localStorage.getItem(field);
};
function App() {
  const [step, setStep] = useState(getLocalStorage("step") ? parseInt(getLocalStorage("step")) : 0);
  console.log("step", step);
  useEffect(() => {
    setLocalStorage("step", step);
  }, [step]);
  const saveLocalStorage = obj => {
    for (const [field, value] of Object.entries(obj)) {
      setLocalStorage(field, value);
    }
  };
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="App">
      <Header className="Header" />
      <div className="container">
        <Paper elevation={24}>
          <Grid container spacing={0}>
            <Grid item xs={6} md={5} sx={{ backgroundColor: "#2c6dc1", padding: "2em", minHeight: "40em", height: "auto" }}>
              <Container>
                {/* <div>
                  <h2 style={{ color: "white" }}>
                    Product <span style={{ color: "#1c3075" }}>Onboarding Checklist</span>
                  </h2>  </div> */}
                  <div className="company-info">
                        <h3 className="white">Product <span> Onboarding Checklist</span></h3>
                        <p className="white">This checklist enables Coresight to provide optimized products by ensuring that all clients provide the information necessary for development.</p><br />
                        <h3 className="white">Coresight</h3>
                        <a href="https://www.linkedin.com/company/c-core/mycompany/"><FaLinkedin size="25px" color="white" /></a>
                        <a href="https://twitter.com/ccore1975"><FaTwitter size="25px" color="white" /></a>
                        <a href="http://t.co/mA6pqCth"><FaLink size="25px" color="white" /></a>
                        <a href="mailto:info@c-core.ca"><FaMailBulk size="25px" color="white" /></a>
                    </div>
               
              </Container>
            </Grid>
            <Grid item xs={6} md={7} sx={{ padding: "2em" }}>
              {step === 0 && (
                <ProductOwnerDetails
                  onNext={obj => {
                    console.log("ProductOwnerDetails", obj);
                    saveLocalStorage(obj);
                    setStep(t => t + 1);
                  }}
                ></ProductOwnerDetails>
              )}
              {step === 1 && (
                <ProductSpecifications
                  onPrevious={() => {
                    setStep(t => t - 1);
                  }}
                  onNext={obj => {
                    console.log("ProductSpecifications", obj);
                    saveLocalStorage(obj);
                    setStep(t => t + 1);
                  }}
                ></ProductSpecifications>
              )}
              {step === 2 && (
                <FileUploads
                  onPrevious={() => {
                    setStep(t => t - 1);
                  }}
                  onNext={obj => {
                    // console.log("DataClassification", obj);
                    // saveLocalStorage(obj);
                    setStep(t => t + 1);
                  }}
                ></FileUploads>
              )}
              {step === 3 && (
                <DataClassification
                  onPrevious={() => {
                    setStep(t => t - 1);
                  }}
                  onNext={obj => {
                    console.log("DataClassification", obj);
                    saveLocalStorage(obj);
                    setStep(t => t + 1);
                  }}
                ></DataClassification>
              )}

              {step === 4 && (
                <ExistingServiceRelations
                  onPrevious={() => {
                    setStep(t => t - 1);
                  }}
                  onNext={obj => {
                    saveLocalStorage(obj);
                    const name = getLocalStorage("name");
                    const email = getLocalStorage("email");
                    const productName = getLocalStorage("productName");
                    const productDescription = getLocalStorage("productDescription");
                    const fileNameTemplate = getLocalStorage("fileNameTemplate");
                    const fileTypes = getLocalStorage("fileTypes");
                    const dataClassification = getLocalStorage("dataClassification");
                    //file
                    const imageLink = getLocalStorage("imageLink");
                    const publishFrequency = getLocalStorage("publishFrequency");
                    const productRelation = getLocalStorage("productRelation");
                    const relatedProducts = getLocalStorage("relatedProducts");
                    const realTimeProduct = getLocalStorage("realTimeProduct");
                    const tmp = { name, email, productName, productDescription, fileNameTemplate, fileTypes, dataClassification, publishFrequency, productRelation, relatedProducts, realTimeProduct, imageLink };
                    db.collection("form-data")
                      .add(tmp)
                      .then(docRef => {
                     
                        for (const [field] of Object.entries(tmp)) {
                          localStorage.removeItem(field);
                          console.log("removeField", field);
                         
                        }

                        setMessage(
                          Object.entries(tmp).map(([field, value]) => (
                            <p>
                              <b>{field}</b> : {value}
                            </p>
                          ))
                        );
                        setOpen(true);
                        setStep(0);
                        console.log("Document written with ID: ", docRef.id);
                      })
                      .catch(error => {
                        console.error("Error adding document: ", error);
                      });
                    // alert(JSON.stringify(tmp));
                  }}
                ></ExistingServiceRelations>
              )}
            </Grid>
          </Grid>
          <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              <h2>Submit Success</h2>
            </DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </Paper>
      </div>
    </div>
  );
}

export default App;
//let mes = "";
//mes += <p>{`${field} : ${value}`}</p>;