import React from 'react'
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'

import { DragDrop } from '@uppy/react'
import { ProgressBar } from '@uppy/react'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'

const uppy = new Uppy({
    id: 'uppyOne',
    meta: { type: 'avatar' },
    restrictions: { maxNumberOfFiles: 1 },
    autoProceed: true
})

const uppy2 = new Uppy({
  id: 'uppyTwo',
  meta: { type: 'avatar' },
  restrictions: { maxNumberOfFiles: 1 },
  autoProceed: true
})

uppy.use(AwsS3, {
    getUploadParameters (file) {
      // Send a request to our PHP signing endpoint.
      return fetch('./s3-upload', {
        method: 'post',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          filename: file.name,
          contentType: file.type,
        }),
      }).then((response) => {
        return response.json()
      }).then((data) => {
        // Required S3 Upload Params.
        return {
            method: data.method,
            url: data.url,
            fields: data.fields,
            headers: {
                'Content-Type': file.type
            }
        }
      })
    },
  })
uppy.on('upload-success', (file, data) => {
    console.log(file, data)
})



const Upload = (formData, setFormData) => {
    return (
        /*<>
            This is the Upload Component
        </>*/
        <div>
            <DragDrop
            width="90%"
            height="120px"
            uppy={uppy}
            locale={{
            strings: {
                // Text to show on the droppable area.
                // `%{browse}` is replaced with a link that opens the system file selection dialog.
                dropHereOr: 'Drop here or browse',
                // Used as the label for the link that opens the system file selection dialog.
                browse: 'browse'
            }
            }}
            />
            <ProgressBar
                uppy={uppy}
                fixed
                hideAfterFinish
            /><br/> <br/>
        <label>Please upload any ancillary files here:</label>
        <DragDrop
            width="90%"
            height="120px"
            uppy={uppy2}
            locale={{
            strings: {
                // Text to show on the droppable area.
                // `%{browse}` is replaced with a link that opens the system file selection dialog.
                dropHereOr: 'Drop here or browse',
                // Used as the label for the link that opens the system file selection dialog.
                browse: 'browse'
            }
            }}
            />
            <ProgressBar
                uppy={uppy2}
                fixed
                hideAfterFinish
            />
        </div>

        
    )
}
export default Upload