// material
import { Alert, Grid, Snackbar, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { actionDefault, getAction } from "../components/FormComp";
import { storage } from "../firebase";

export default function DataClassification({ onPrevious = () => { }, onNext = () => { } }) {
  const [image, setImage] = useState(undefined);
  const [, setLinkImageUpload] = useState("");
  const [alert, setAlert] = useState({ open: false, message: "", severity: "error" });

  useEffect(() => {

    const upload = () => {
      if (image == null) return;
      const uploadTask = storage.ref(`/images/${image.name}`).put(image);

      uploadTask.on(
        "state_changed",
        resp => {
          console.log("resp", resp);
        },
        error => {
          console.error(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            setLinkImageUpload(downloadURL);
            formik.setFieldValue("imageLink", downloadURL);
            setAlert({ open: true, message: "Upload image success!!", severity: "success" });
          });
        }
      );
    };

    if (image) upload();
    // eslint-disable-next-line
  }, [image]);
  const schema = Yup.object().shape({
    dataClassification: Yup.string().required("Field should not be empty"),
    publishFrequency: Yup.string().required("Field should not be empty"),
  });

  const formik = useFormik({
    initialValues: {
      dataClassification: "Yes",
      publishFrequency: "Daily",
      imageLink: "",
    },
    validationSchema: schema,
    onSubmit: value => {
      if (value.dataClassification === "No") {
        value.imageLink = "";
      } else {
        if (value.imageLink === undefined || value.imageLink === "") {
          setAlert({ open: true, message: "Image not yet uploaded", severity: "error" });
          return;
        }
      }
      onNext(value);
    },
  });
  return (
    <div>
      <h2>Data Classification</h2>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Grid container rowSpacing={8} spacing={4}>
            <Grid item md={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Will you product require data classification?</FormLabel>
                <RadioGroup aria-label="gender" defaultValue="Yes" onChange={formik.handleChange} name="dataClassification">
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth component="fieldset" disabled={formik.values.dataClassification === "No"}>
                <FormLabel component="legend">Please provide an image/file of the data classification/symbology to be used</FormLabel>
                <TextField
                  disabled={formik.values.dataClassification === "No"}
                  fullWidth
                  type="file"
                  onChange={e => {
                    setImage(e.target.files[0]);
                  }}
                />
                {/* {linkImageUpload !== "" && (
                  // <a href={linkImageUpload} target="_blank">
                  //   Image in Filebase Storage
                  // </a>
                  <a href={linkImageUpload} target="_blank">
                    <img src={linkImageUpload} style={{ maxWidth: "35em", maxHeight: "15em" }}></img>
                  </a>
                )} */}
                {/* <button onClick={upload}>Upload</button> */}
              </FormControl>
              <a target="_blank" href="/img/symbology.png">
                Click here to see sample image file
              </a>
            </Grid>
            <Grid item md={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">What is the frequency of which your data will be published?</FormLabel>
                <RadioGroup aria-label="gender" value={formik.values.publishFrequency ? formik.values.publishFrequency : "Daily"} name="publishFrequency" onChange={formik.handleChange}>
                  <FormControlLabel value="Daily" control={<Radio />} label="Daily" />
                  <FormControlLabel value="Weekly" control={<Radio />} label="Weekly" />
                  <FormControlLabel value="Monthly" control={<Radio />} label="Monthly" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          {getAction(actionDefault(onPrevious, formik.handleSubmit))}
        </Form>
      </FormikProvider>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000} open={alert.open}>
        <Alert onClose={() => setAlert(t => ({ ...t, open: false }))} severity={alert.severity} sx={{ width: "100%" }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
