import * as Yup from "yup";
//import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Stack, TextField, Grid, Button } from "@mui/material";

// ----------------------------------------------------------------------
export function actionDefault(leftClickFunc = () => {}, rightClickFunc = () => {}) {
  return {
    left: {
      label: "Previous",
      onClick: leftClickFunc,
    },
    right: {
      label: "Next",
      onClick: rightClickFunc,
    },
  };
}

export function actionRightDefault(rightClickFunc = () => {}) {
  return {
    right: {
      label: "Next",
      onClick: rightClickFunc,
    },
  };
}
export function getAction(action = actionDefault()) {
  return (
    <Stack direction={{ xs: "column", sm: "row" }} sx={{ justifyContent: "center", paddingTop: "2em" }} spacing={4}>
      {action.left !== undefined && action.left.label && (
        <Button
          size="large"
          variant="contained"
          sx={{ width: "8em" }}
          onClick={() => {
            action.left.onClick();
          }}
        >
          {action.left.label}
        </Button>
      )}
      <Button
        size="large"
        variant="contained"
        sx={{ width: "8em" }}
        onClick={() => {
          action.right.onClick();
        }}
      >
        {action.right.label}
      </Button>
    </Stack>
  );
}
export default function FormComp({ title = "", action = actionDefault(), data = {} }) {

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
    name: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "nhatnam10a3@gmail.com",
      name: "test",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: value => {
      action.right.onClick(value);
      //   navigate("/dashboard", { replace: true });
    },
  });
  const { errors, touched, getFieldProps } = formik;

  // const handleShowName = () => {
  //   setShowName(show => !show);
  // };

  return (
    <div>
      <h2>{title}</h2>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Grid container spacing={4}>
            <Grid item xs={6} md={6}>
              <TextField fullWidth autoComplete="username" type="text" label="Name" {...getFieldProps("name")} error={Boolean(touched.name && errors.name)} helperText={touched.name && errors.name} />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField fullWidth autoComplete="username" type="email" label="Email address" {...getFieldProps("email")} error={Boolean(touched.email && errors.email)} helperText={touched.email && errors.email} />
            </Grid>
          </Grid>
          <Stack direction={{ xs: "column", sm: "row" }} sx={{ justifyContent: "center", paddingTop: "2em" }} spacing={4}>
            {action.left.label && (
              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ width: "8em" }}
                onClick={() => {
                  action.left.onClick();
                }}
              >
                {action.left.label}
              </Button>
            )}
            <Button
              type="submit"
              size="large"
              variant="contained"
              sx={{ width: "8em" }}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {action.right.label}
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </div>
  );
}
