// material
import { Grid, TextField } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
//import { useState } from "react";
import * as Yup from "yup";
import { actionDefault, getAction } from "../components/FormComp";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function ProductSpecifications({ onPrevious = () => {}, onNext = () => {} }) {
  const LoginSchema = Yup.object().shape({
    productDescription: Yup.string().required("Field should not be empty"),
    productName: Yup.string().required("Field should not be empty"),
    fileNameTemplate: Yup.string().required("Field should not be empty"),
    fileTypes: Yup.string().required("Field should not be empty"),
  });

  const formik = useFormik({
    initialValues: {
      productName: "",
      productDescription: "",
      fileNameTemplate: "",
      fileTypes: "jpeg",
    },
    validationSchema: LoginSchema,
    onSubmit: value => {
      onNext(value);
    },
  });
  const { errors, touched, getFieldProps } = formik;

  return (
    <div>
      <h2>Product Specifications</h2>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Grid container rowSpacing={8} spacing={4}>
            <Grid item md={6}>
              <TextField fullWidth autoComplete="productName" type="text" label="Product Name" {...getFieldProps("productName")} error={Boolean(touched.productName && errors.productName)} helperText={touched.productName && errors.productName} />
            </Grid>
            <Grid item md={12}>
              <TextField fullWidth autoComplete="productDescription" multiline rows={4} label="Product Description" {...getFieldProps("productDescription")} error={Boolean(touched.productDescription && errors.productDescription)} helperText={touched.productDescription && errors.productDescription} />
            </Grid>
            <Grid item md={6}>
              <TextField fullWidth autoComplete="fileNameTemplate" type="text" label="File Name Template" {...getFieldProps("fileNameTemplate")} error={Boolean(touched.fileNameTemplate && errors.fileNameTemplate)} helperText={touched.fileNameTemplate && errors.fileNameTemplate} />
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel id="fileTypes">File Type(s)</InputLabel>
                <Select labelId="demo-simple-select-label" id="fileTypes" name="fileTypes" label="File Type(s)" value={formik.values.fileTypes} onChange={formik.handleChange}>
                  <MenuItem value="shp">shp</MenuItem>
                  <MenuItem value="jpeg">jpeg</MenuItem>
                  <MenuItem value="tiff">tiff</MenuItem>
                </Select>
              </FormControl>
              {/* <TextField fullWidth autoComplete="fileType" type="text" label="File Type(s)" {...getFieldProps("fileType")} error={Boolean(touched.fileType && errors.fileType)} helperText={touched.fileType && errors.fileType} /> */}
            </Grid>
          </Grid>

          {getAction(actionDefault(onPrevious, formik.handleSubmit))}
        </Form>
      </FormikProvider>
    </div>
  );
}
