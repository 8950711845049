// material
import { Grid } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { actionDefault, getAction } from "../components/FormComp";
import UppyComp from "./UppyComp";

export default function FileUploads({ onPrevious = () => {}, onNext = () => {} }) {
  const formik = useFormik({
    initialValues: {
      sampleFile: "",
      ancilliaryFile: "",
    },
    onSubmit: value => {
      onNext(value);
    },
  });
 

  return (
    <div>
      <h2>File Uploads</h2>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Grid container rowSpacing={8} spacing={4}>
            <Grid item md={12}>
              <label>Please upload a sample data file to be used for your product:</label>
              <UppyComp />
            </Grid>
          </Grid>
          {getAction(actionDefault(onPrevious, formik.handleSubmit))}
        </Form>
      </FormikProvider>
    </div>
  );
}
