// material
import { Grid, TextField } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
//import { useState } from "react";
import * as Yup from "yup";
import { actionRightDefault, getAction } from "../components/FormComp";

export default function ProductOwnerDetails({ onNext = () => {} }) {
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Email format is incorrect").required("Field should not be empty"),
    name: Yup.string().required("Field should not be empty"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: LoginSchema,
    onSubmit: value => {
      onNext(value);
    },
  });
  const { errors, touched, getFieldProps } = formik;

  return (
    <div>
      <h2>Product Owner Details</h2>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Grid container spacing={4}>
            <Grid item xs={6} md={6}>
              <TextField fullWidth autoComplete="name" type="text" label="Name" {...getFieldProps("name")} error={Boolean(touched.name && errors.name)} helperText={touched.name && errors.name} />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField fullWidth autoComplete="email" type="email" label="Email address" {...getFieldProps("email")} error={Boolean(touched.email && errors.email)} helperText={touched.email && errors.email} />
            </Grid>
          </Grid>
          {getAction(actionRightDefault(formik.handleSubmit))}
        </Form>
      </FormikProvider>
    </div>
  );
}
