// material
import { Grid } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
//import { useState } from "react";
import * as Yup from "yup";
import { getAction } from "../components/FormComp";
//import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

export default function ExistingServiceRelations({ onPrevious = () => {}, onNext = () => {} }) {
  const LoginSchema = Yup.object().shape({
    productRelation: Yup.string().required("Field should not be empty"),
    realTimeProduct: Yup.string().required("Field should not be empty"),
    publishFrequency: Yup.string().required("Field should not be empty"),
    relatedProducts: Yup.string().required("Field should not be empty"),
  });

  const formik = useFormik({
    initialValues: {
      realTimeProduct: "Yes",
      // filenameTemplate: "filenameTemplate",
      // fileInput: "10",
      productRelation: "Yes",
      publishFrequency: "Daily",
      relatedProducts: "River Ice",
    },
    validationSchema: LoginSchema,
    onSubmit: value => {
      if (value.productRelation === "No") {
        value.relatedProducts = "";
      }
      onNext(value);
    },
  });
  return (
    <div>
      <h2>Existing ServiceRelations</h2>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Grid container rowSpacing={8} spacing={4}>
            <Grid item md={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Is your product related to any existing service(s)?</FormLabel>
                <RadioGroup aria-label="gender" defaultValue="Yes" name="productRelation" onChange={formik.handleChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth disabled={formik.values.productRelation === "No"}>
                <FormLabel id="relatedProducts">To what existing serivce(s) is your product related?</FormLabel>
                <Select labelId="demo-simple-select-label" id="relatedProducts" name="relatedProducts" label="File Type(s)" value={formik.values.relatedProducts} onChange={formik.handleChange} disabled={formik.values.productRelation === "No"}>
                  <MenuItem value="River Ice">River Ice</MenuItem>
                  <MenuItem value="RPAS">RPAS</MenuItem>
                  <MenuItem value="Polar Code">Polar Code</MenuItem>
                  <MenuItem value="Maritime Surveillance Service">Maritime Surveillance Service</MenuItem>
                  <MenuItem value="Sherlock">Sherlock</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Lastly, is your product a real-time product?</FormLabel>
                <RadioGroup aria-label="gender" value={formik.values.realTimeProduct ? formik.values.realTimeProduct : "Yes"} onChange={formik.handleChange} name="realTimeProduct">
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          {/* {getAction(actionDefault(onPrevious, formik.handleSubmit))} */}
          {getAction({
            left: {
              label: "Previous",
              onClick: onPrevious,
            },
            right: {
              label: "Submit",
              onClick: formik.handleSubmit,
            },
          })}
        </Form>
      </FormikProvider>
    </div>
  );
}
