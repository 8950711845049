import firebase from "firebase";

const firebaseConfig = {
         apiKey: "AIzaSyDuLBqNIOdVM4MYmkdgWP7vz7paphJkQR4",
         authDomain: "coresight-checklist.firebaseapp.com",
         databaseURL: "https://coresight-checklist-default-rtdb.firebaseio.com",
         projectId: "coresight-checklist",
         storageBucket: "coresight-checklist.appspot.com",
         messagingSenderId: "1003309023889",
         appId: "1:1003309023889:web:e77e9f46c84e7ff7e07530",
         measurementId: "G-E7J736X36L"
};
firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();
var db = firebase.firestore();
export { storage, db };
